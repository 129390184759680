span.twitter-typeahead {
  width: 100%;

  .tt-menu {
    @extend .dropdown-menu;
    margin: 0;
    padding: 0;
    border-radius: 0;
    border-top: 0;
    width: 100%;
    top: 99% !important;
    border: 0;
  }

  .tt-dropdown-menu {
    @extend .dropdown-menu;
  }

  .tt-suggestion {
    @extend .dropdown-item;
  }

  .tt-suggestion.tt-cursor {
     @extend .dropdown-item.active;

     a {
       color: #fff;
     }
  }

  .input-group & {
    display: block !important;
    .tt-dropdown-menu {
      top:32px !important;
    }
  }
  .input-group.input-group-lg & {
    .tt-dropdown-menu {
      top:44px !important;
    }
  }
  .input-group.input-group-sm & {
    .tt-dropdown-menu {
      top:28px !important;
    }
  }
}
