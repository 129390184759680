.carousel.flex {

  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right,
  .carousel-item.active {
    display: flex;
    background-color: #f5f7fc;
  }

  @media (max-width: 575.98px) {
    .carousel-item {
      width: auto;
    }

    .carousel-control-prev, .carousel-control-next {
      top: auto;
      bottom: ( $grid-gutter-width * 1.5 );
    }
  }

  @include media-breakpoint-up(sm) {
    .carousel-control-next,
    .carousel-control-prev {
      transform: translateY(-65px);
    }
  }
}

.carousel-container {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
  padding: 1.5rem 1rem;
  background-color: #fff;
  position: relative;

  box-shadow: $box-shadow-sm;

}

#homepageMaincarousel .carousel-inner {
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 9px;
    height: 380px;
    display: block;
    z-index: 1000;
    background-image: $perforation-left;
    background-repeat: repeat-y;
    background-position: 0 0;
  }
  &:after {
    position: absolute;
    top: 0;
    right: 0;
    content: '';
    width: 9px;
    height: 380px;
    display: block;
    z-index: 1000;
    background-image: $perforation-right;
    background-repeat: repeat-y;
    background-position: 0 0;
  }

}

@media (min-width: 575.98px) {
.carousel-no-margins {

    .carousel-item.row {
      margin-left: 0;

      .col {
        padding-right: 0;
        padding-left: 0;
      }
    }
    
  }
}

// Optional indicator pips
//
// Add an ordered list with the following class and add a list item for each
// slide your carousel holds.

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: -40px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0; // override <ol> default
  // Use the .carousel-control's width as margin so we don't overlay those
  margin-right: $carousel-control-width;
  margin-left: $carousel-control-width;
  list-style: none;

  li {
    position: relative;
    flex: 0 1 auto;
    width: $carousel-indicator-width;
    height: $carousel-indicator-height;
    margin-right: $carousel-indicator-spacer;
    margin-left: $carousel-indicator-spacer;
    text-indent: -999px;
    cursor: pointer;
    background-color: rgba($carousel-indicator-active-bg, .5);
    border-radius: 50%;
    border:   2px solid $carousel-indicator-active-bg;

    // Use pseudo classes to increase the hit area by 10px on top and bottom.
    &::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: "";
    }
    &::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: "";
    }
  }

  .active {
    background-color: $carousel-indicator-active-bg;
  }
}