#map {
  height: $google-maps-height;
  width: $google-maps-width;
  background-color: $body-bg;
}

.infoBox {
  min-width: 280px;
  font-size: $font-size-base;
  line-height: $line-height-base;

  .box-item-title {
    border: 0;
  }

  p {
    margin-bottom: 0;
  }

  > img {
    z-index: 1;
    margin-right: -16px !important;
    margin-top: -1px !important;
  }
}