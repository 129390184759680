.alert-success {
  color: $body-color;
  background-color: $green;
  border-color: $green;
}

.alert-danger {
  color: $light;
  background-color: $red;
  border-color: $red;
}