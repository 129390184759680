.box {
  background: #FFF;
  transition: transform 0.4s, box-shadow 0.4s;
  position: relative;
  margin-top:    ( $grid-gutter-width /2 );
  margin-bottom: ( $grid-gutter-width *4 );

  .badge {
    margin: 0 ( $grid-gutter-width /2);
    position: relative;
    top: -4px;
  }

  .box-content {
    margin-bottom: ($grid-gutter-width /2 );
    padding-bottom: ($grid-gutter-width /2 );
    position: relative;
  }

  .box-item-img-wrapper {
    overflow: hidden;
  }

  .box-item-date,
  .box-item-lacation,
  .box-item-btn {
    margin-left:  ( $grid-gutter-width /2 );
    margin-right: ( $grid-gutter-width /2 );
  }

  .box-item-date,
  .box-item-lacation {
    height: $line-height-base+em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .box-item-title {
    font-size: 1rem;
    text-transform: uppercase;
    padding: ( $grid-gutter-width /2 );
    border-bottom: 1px solid $my-gray;
    
    span {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .box-item-btn,
  .box-item-date {
    margin-top:  ( $grid-gutter-width /2 );
  }

  .box-item-date,
  .box-item-lacation {
    i {
      color: $red;
    }
  }

  .perforation-top {
    width: 100%;
    overflow: hidden;
    position: absolute;
    height: 10px;
    top: -1px;
    left: 0;
    z-index: 1;

    img {
      display: block;
      height: 8px;
    }
  }

  .perforation-bottom {
    width: 100%;
    overflow: hidden;
    position: absolute;
    height: 10px;
    bottom: -10px;
    left: 0;

    img {
      display: block;
      height: 8px;
      filter: drop-shadow( $box-shadow-sm );
    }
  }

  &.box-animate {

    .badge,
    .box-item-img,
    .box-item-date,
    .box-item-lacation {
      transition: transform 0.4s;
    }

    .badge {
      margin: 0 $grid-gutter-width;
    }

    .box-item-title {
      opacity: 0;
      width: 100%;
      position: absolute;
      top: auto;
      transition: transform 0.4s, opacity 0.1s;
    }

    .box-item-btn {
      opacity: 0;
      position: absolute;
      bottom: auto;
      transition: transform 0.4s , opacity 0.1s;
    }

    &:hover {
      .badge,
      .box-item-img,
      .box-item-date,
      .box-item-lacation,
      .box-item-title {
        transform: translateY(-170px);
      }

      .box-item-date,
      .box-item-lacation {
        transform: translateY(-55px);
      }

      .box-item-img {
        opacity: 0
      }

      .box-item-title {
        opacity: 1;
        transition: transform 0.4s, opacity 0.6s
      }

      .box-item-btn {
        opacity: 1;
        transform: translateY(-55px);
        transition: transform 0.4s, opacity 0.4s 0.1s
      }

    }

  }

  @media (min-width: 575.98px) {

    margin-bottom: ( $grid-gutter-width *1.5 );

    .box-item-date,
    .box-item-lacation,
    .box-item-btn {
      margin-left:  $grid-gutter-width;
      margin-right:  $grid-gutter-width;
    }

    .box-item-title {
      padding: $grid-gutter-width;
    }

    &.box-animate:hover {
      .box-item-img {
        opacity: 1
      }
    }

  }
}

.box-masonry {
  @include media-breakpoint-up(sm) {
    column-count: $masonry-columns-count;
    column-gap: $masonry-columns-gap;
    orphans: 1;
    widows: 1;

    .box {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
      margin-bottom: ( $grid-gutter-width / 2 );
    }
  }
}

