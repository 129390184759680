.modal-content {

  > .close {
    padding: .5rem;
    margin: $modal-header-padding;
  }

  .close-red {
    float: right;
    font-size: .7rem;
    font-weight: normal;
    line-height: 1;
    color: $white;
    text-shadow: none;
    opacity: 1;
    background-color: $red;
    border-radius: 100%;

    &:focus {
      outline-style: none;
    }
  }

  &.modal-dark {
    background-color: #f6f7fc;
  }

  @include media-breakpoint-down(md) {
    .btn {
      display: block;
      width: 100%;
    }
  }
}

.modal-body {
  padding: 3rem;

  &.modal-dark {
    background-color: #f6f7fc;
  }

  &.modal-darker {
    background-color: #9fffcb;
  }

  ul.list-group-striped {
    .list-group-item:nth-child(odd) { background-color: $list-group-hover-bg; }
    .list-group-item:nth-child(even) { background-color: $white; }
  }
}

.modal-header,
.modal-footer {
  border: 0;
}