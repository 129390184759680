@font-face {
  font-family: 'predpredaj-icons';
  src:  url('#{$icomoon-font-path}/predpredaj-icons.eot?rq73zg');
  src:  url('#{$icomoon-font-path}/predpredaj-icons.eot?rq73zg#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/predpredaj-icons.ttf?rq73zg') format('truetype'),
    url('#{$icomoon-font-path}/predpredaj-icons.woff?rq73zg') format('woff'),
    url('#{$icomoon-font-path}/predpredaj-icons.svg?rq73zg#predpredaj-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'predpredaj-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-37 {
  &:before {
    content: $icon-37;
  }
}
.icon-36 {
  &:before {
    content: $icon-36;
  }
}
.icon-35 {
  &:before {
    content: $icon-35;
  }
}
.icon-34 {
  &:before {
    content: $icon-34;
  }
}
.icon-33 {
  &:before {
    content: $icon-33;
  }
}
.icon-32 {
  &:before {
    content: $icon-32;
  }
}
.icon-31 {
  &:before {
    content: $icon-31;
  }
}
.icon-30 {
  &:before {
    content: $icon-30;
  }
}
.icon-29 {
  &:before {
    content: $icon-29;
  }
}
.icon-28 {
  &:before {
    content: $icon-28;
  }
}
.icon-27 {
  &:before {
    content: $icon-27;
  }
}
.icon-26 {
  &:before {
    content: $icon-26;
  }
}
.icon-25 {
  &:before {
    content: $icon-25;
  }
}
.icon-24 {
  &:before {
    content: $icon-24;
  }
}
.icon-23 {
  &:before {
    content: $icon-23;
  }
}
.icon-22 {
  &:before {
    content: $icon-22;
  }
}
.icon-21 {
  &:before {
    content: $icon-21;
  }
}
.icon-20 {
  &:before {
    content: $icon-20;
  }
}
.icon-19 {
  &:before {
    content: $icon-19;
  }
}
.icon-18 {
  &:before {
    content: $icon-18;
  }
}
.icon-17 {
  &:before {
    content: $icon-17;
  }
}
.icon-16 {
  &:before {
    content: $icon-16;
  }
}
.icon-15 {
  &:before {
    content: $icon-15;
  }
}
.icon-14 {
  &:before {
    content: $icon-14;
  }
}
.icon-13 {
  &:before {
    content: $icon-13;
  }
}
.icon-11 {
  &:before {
    content: $icon-11;
  }
}
.icon-10 {
  &:before {
    content: $icon-10;
  }
}
.icon-09 {
  &:before {
    content: $icon-09;
  }
}
.icon-08 {
  &:before {
    content: $icon-08;
  }
}
.icon-07 {
  &:before {
    content: $icon-07;
  }
}
.icon-06 {
  &:before {
    content: $icon-06;
  }
}
.icon-05 {
  &:before {
    content: $icon-05;
  }
}
.icon-04 {
  &:before {
    content: $icon-04;
  }
}
.icon-03 {
  &:before {
    content: $icon-03;
  }
}
.icon-02 {
  &:before {
    content: $icon-02;
  }
}
.icon-01 {
  &:before {
    content: $icon-01;
  }
}

