.custom-select {
  width: 100% !important;
  padding: .375rem 2.5rem .375rem .75rem;
  background-size: 18px 20px;
  background-color: inherit;
  border: 0;

    &:after {
    display: inline-block;
    font-family: 'predpredaj-icons';
    content: "\e924";
    border: 0 !important;
    vertical-align: inherit;
    color: $red;
  }
}

// webkit
select.select-text-right {
  text-align: right;
  text-align-last: right;
}
// reset to left
option.select-text-right {
  text-align: left;
}

.on-white-bg {
  .form-control {
    background-color: $input-bg-dark !important;
  }


}

// adding asterix to label in required fields
.form-group.required label:after { 
  content:" *";
  color:red;
}
.form-check.required label a:after {
  content:" *";
  color:red;
}