footer {
  background-color: #FFF;
  border-top: 1px solid $my-gray;

  .socials {
    color: $my-blue;

    a {
      font-size: 2rem;
      text-decoration: none;
      color: $my-blue;
      vertical-align: middle;
    }
  }

  .copy {
    border-top: 1px solid $my-gray;
  }

  .nav {
    white-space: nowrap;
  }

  @include media-breakpoint-down(sm) { 
    .nav-footer-second {
      border-top: 1px solid $my-gray;
      text-align: center; 
    }
  }
}