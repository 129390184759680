.partners-menu-affix {
  @supports (position: sticky) {
    position: sticky;
    top: 14rem;
    transform: translateX(-100%);
    height: 0;
    overflow-y: visible;
  }
  padding-top: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: .875rem;
  white-space: nowrap;
}

.partners-jumbotron {

  .jumbotron {
    min-height: 580px;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      min-height: 580px;
      width: 100%;
      background-image: $partners-jumbotron-img;
      background-repeat: no-repeat;
      background-size: contain;
      background-position-x: 100%;
      transform: translateX(15%);
      z-index: -1;

    }

    @include media-breakpoint-down(md) {
      .col {
        min-height: 580px;
        display: flex;
      }

      .partners-jumbotron-title {
        font-size: 2.2rem;
      }

      .partners-jumbotron-buttons,
      .partners-jumbotron-buttons .btn {
        width: 100%;
      }

      &:after {
        background-size: cover;
        background-position: 50% 0;
        transform: none;
        z-index: -1;
      }
    }
  }



}

.partners-stats {
  position: relative;

  @include media-breakpoint-up(md) {
    &:before {
      position: absolute;
      content: ' ';
      background-image: $bottom-perforation;
      display: block;
      width: 80%;
      height: 100px;
      transform: translateX(15%);
      opacity: .2;
      top: -10px;
    }
  }
}

.partners-benefits {

  .partners-benefits-item {
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      opacity: .2;
      background-image: $right-perforation;
      background-repeat: repeat-y;
      background-position-x: 100%;

      @include media-breakpoint-down(md) {
        display: block;
        background-image: $bottom-perforation;
        background-repeat: repeat-x;
        background-position-y: 100%;
      }
    }

    &:nth-child(3n) {
      @include media-breakpoint-up(lg) {
        &:before {
          display: none;
        }
      }
    }
  }


}

