@font-face {
  font-family: 'Etelka';
  src: url("../fonts/Etelka-Bold.eot");
  src: url("../fonts/Etelka-Bold.eot?#iefix") format('embedded-opentype'),
       url("../fonts/Etelka-Bold.ttf") format('truetype'),
       url("../fonts/Etelka-Bold.woff2") format('woff2'),
       url("../fonts/Etelka-Bold.woff") format('woff'),
       url("../fonts/Etelka-Bold.svg#Etelka_textbold") format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Etelka';
  src: url('../fonts/Etelka-Light.eot');
  src: url('../fonts/Etelka-Light.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Etelka-Light.woff2') format('woff2'),
       url('../fonts/Etelka-Light.woff') format('woff'),
       url('../fonts/Etelka-Light.svg#etelka_lightregular') format('svg');
  font-weight: 300;
  font-style: normal;
}