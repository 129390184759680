#homepageMaincarousel {
  overflow: hidden;
  height: 360px;
  @include media-breakpoint-down(lg) {
    height: 300px;
  }
  @include media-breakpoint-down(md) {
    height: 220px;
  }
  @include media-breakpoint-down(sm) {
    height: 160px;
  }
}
.slick-slide {
  img {
    max-width: 100%;
  }
  .perforation-bottom {
    img {
      width: auto;
      max-width: none;
    }
  }
}
#homepageMaincarousel {
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 9px;
    height: 100%;
    display: block;
    z-index: 1000;
    background-image: $perforation-left;
    background-repeat: repeat-y;
    background-position: 0 0;
  }
  &:after {
    position: absolute;
    top: 0;
    right: 0;
    content: '';
    width: 9px;
    height: 100%;
    display: block;
    z-index: 1000;
    background-image: $perforation-right;
    background-repeat: repeat-y;
    background-position: 0 0;
  }
}
.small {
  .slick-arrow-prev, .slick-arrow-next {
    transform: translateY(-65px);
    @include media-breakpoint-down(xs) {
       transform: translateY(0);
       top: auto;
       bottom: 45px;
    }
  }
  .box {
    @include media-breakpoint-down(xs) {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}
.slick-arrow-prev, .slick-arrow-next {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: .5;
    z-index: 10;
    cursor: pointer;
}
.slick-arrow-prev, .slick-arrow-next {
    &:hover, &:focus {
      color: #fff;
      text-decoration: none;
      outline: 0;
      opacity: .9;
    }
}
.slick-arrow-prev {
    left: 0;
}
.slick-arrow-next {
    right: 0;
}
.slick-dots {
    margin: 0;
    padding: 12px 0 0 0;
    text-align: center;
    list-style-type: none;
    li {
        display: inline-block;
        margin: 0 3px;
        button {
          width: 12px;
          height: 12px;
          display: block;
          margin: 0;
          padding: 0;
          text-indent: -9999px;
          cursor: pointer;
          background-color: rgba(219,220,233,0.5);
          border-radius: 50%;
          border: 2px solid $my-gray;
        }
        &.slick-active {
          button {
            background-color: $my-gray;
          }
        } 
    }
}