h4, .h4 {
  font-family: $font-family-sans-serif;
  font-weight: 300;
}

.color-red {
  color: $red;
}

.color-blue {
  color: $my-blue;
}

b,
strong {
  font-weight: bold;
}