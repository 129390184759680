.navbar {
  background-color: #ffffff;
  border-bottom: 1px solid $my-gray;
  padding: 1rem;

  &.navbar-main {
    // z-index pre search dropdovn s modal backdrop
    z-index: 1050;
  }

  &.navbar-secondary {
    border-bottom: 0;
    padding: 0;

    .navbar-collapse {
      padding: 1rem;
      justify-content: space-between;
    }
  }

  .btn-light {
    box-shadow: none;
  }
}

@include media-breakpoint-down(md) {
    .sticky-top.full-height {
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      background-color: $white;
      height: 100vh;
    }
    #top_second_menu {
      padding-bottom: 100px;
    }
}
@include media-breakpoint-down(xs) {

  .navbar-brand > img {
    height: 21px;
  }

}

.nav-item {

  i { vertical-align: middle; }

  .nav-link.dropdown-toggle {
    padding-right: ( $navbar-nav-link-padding-x *2 );
  }

  @include media-breakpoint-up(xl) {
    i { font-size: 1.5em; }
  }

  @include media-breakpoint-down(md) {
    .nav-link.border-right {
      border-right: 0 !important;
    }
  }

}

.cart-item {
  position: relative;
  padding-right: 1rem;
  padding-left: 1rem;

  i { vertical-align: middle; font-size: 1.5em; }
  
  .cart-item-timer,
  .cart-item-count {
    font-size: .65rem !important;
  }
  
  .cart-item-timer,
  .cart-item-count {
    position: absolute;
    top: 0;
    transform: translate(-50%, -25%);
  }

  .cart-item-timer { left: 0; border-radius: $border-radius-lg; }
  .cart-item-count { right: 0; border-radius: 50%; }
}
  
  .nav-pills {
  .nav-link.active,
  .show > .nav-link {
    box-shadow: $box-shadow-btn;
  }
}
.cart_content {
  max-height: 310px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.mini-cart {
  font-size: .9rem;
  width: 280px;

  .sum {
    font-size: 1.2rem;
  }
}

// animated hamburger menu
.navbar-toggler {
  position: relative;
  background-color: transparent;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0;
  box-shadow: none;
  background-color: transparent;
}

.navbar-toggler span {
  display: block;
  background-color: #444;
  height: 3px;
  width: 25px;
  margin-top: 4px;
  margin-bottom: 4px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  position: relative;
  left: 0;
  opacity: 1;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  -webkit-transition: transform .35s ease-in-out;
  -moz-transition: transform .35s ease-in-out;
  -o-transition: transform .35s ease-in-out;
  transition: transform .35s ease-in-out;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
   position: absolute;
   left: 12px;
   top: 10px;
   -webkit-transform: rotate(135deg);
   -moz-transform: rotate(135deg);
   -o-transform: rotate(135deg);
   transform: rotate(135deg);
   opacity: 0.9;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
   height: 12px;
   visibility: hidden;
   background-color: transparent;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
   position: absolute;
   left: 12px;
   top: 10px;
   -webkit-transform: rotate(-135deg);
   -moz-transform: rotate(-135deg);
   -o-transform: rotate(-135deg);
   transform: rotate(-135deg);
   opacity: 0.9;
}