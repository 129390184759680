.dropdown-dark {

  background-color: $dropdown-bg-dark;
  min-width: 200px;

  .dropdown-item {
    color: $dropdown-link-color-dark;
    background-color: $dropdown-link-bg-dark;
    border-bottom: 1px solid $dropdown-item-border-color;

    @include hover-focus {
      color: $dropdown-link-hover-color-dark;
      text-decoration: none;
      @include gradient-bg($dropdown-link-hover-bg-dark);
    }

    &.active,
    &:active {
      color: $dropdown-link-active-color;
      text-decoration: none;
      @include gradient-bg($dropdown-link-active-bg);
    }
  }

  // Dropdown section headers
  .dropdown-header {
    font-size: $font-size-base;
    color: $dropdown-header-color-dark;

    @include hover-focus {
      color: $dropdown-link-hover-color-dark;
      text-decoration: none;
      @include gradient-bg($dropdown-link-hover-bg-dark);
    }
  }

  .dropdown-item-text {
    color: $light;
    border-bottom: 1px solid $dropdown-item-border-color;
  }
}

.dropdown-animated {
    transition: max-height 0.6s, opacity 0.2s 0.1s, visibility 0s 0.3s;

    max-height: 0;
    display: block;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
}

.dropdown-animated.show {
    transition: max-height 0.6s, opacity 0.2s, visibility 0s;
    display: block;
    max-height: calc(100vh - 20px);
    opacity: 1;
    visibility: visible;
}

.dropdown-toggle.custom-caret:after {
  font-family: 'predpredaj-icons';
  content: "\e924";
  border: 0 !important;
  vertical-align: inherit;
  color: $red;
}
