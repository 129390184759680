.steps {

  .steps-item {

    display: inline-block;
    font-size: 1.628rem;
    color: $my-gray;
    margin: 0 $grid-gutter-width;

    span {
      padding: .5rem 1.1rem;
      border: 1px solid $my-gray;
      border-radius: 50%;
    }

    &.active {
      color: $body-color;
      span {
        background-color: $my-gray;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .steps-item {
      font-size: 1.3rem;      
    }
    .steps-item:nth-child(1){
      margin-bottom: $grid-gutter-width;
    }
  }

}