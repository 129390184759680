.banner-partners-info,
.banner-sales-points {
  h3 {
    font-family: $font-family-sans-serif;
    font-weight: normal;
    color: #fff;
  }
}

.banner-sales-points {
  background-image: $banner-bg-sales-points;
}

.banner-partners-info {
  background-image: $banner-bg-partners-info;
}