.search-popup-close {
  display: none;
}
.search-popup {
  position: absolute;
  top: 165px;
  left: 0;
  z-index: 1060;
  float: left;
  width: 100%;
  font-size: $font-size-base;
  color: $body-color;
  text-align: left;
  list-style: none;
  overflow: visible;
  .container {
    z-index: 1060;
    position: relative;
  }
  .multiselect-container {
    margin: 0 -1px;
    width: calc(100% + 2px); 
    padding: 0.6rem 0 0.3rem 0;
  }
  .multiselect-container > li > a {
    &:hover {
      text-decoration: none;
    }
    &:focus {
      outline: none;
    }
  }
  .multiselect-container > li > a > label {
    padding: 0 0.6rem 0.3rem 0.6rem;
    font-size: 0.9rem;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    white-space: nowrap;
  }
  .multiselect-container > li > a > label:focus {
    outline: none;
  }
  .multiselect-container > li > a > label > input[type=checkbox] {
    margin-right: 2px;
  }
  .multiselect-container {
    &.dropdown-menu {
      border-radius: 0;
      border: 1px solid $border-color;
    }
  }
  form {
    padding: 0;
    margin: 0;
    background-color: $dropdown-bg;
    background-clip: padding-box;
    border: $dropdown-border-width solid $border-color;
    position: relative;
    padding-right: 0.25em;

    &:before {
      bottom: 100%;
      left: 2rem;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:before {
      border-color: rgba(255, 33, 33, 0);
      border-bottom-color: $my-gray;
      border-width: 9px;
    }
    button {
      &.multiselect {
        width: 100%;
        padding: 1.2rem 1rem;
        &:focus {
          box-shadow: none;
        }
        &::after {
          margin: -0.2rem 0 0 0.2rem;
          border: 0;
          font-family: 'predpredaj-icons';
          content: "\e924";
          color: $red;
          display: inline-block;
          vertical-align: middle;
        }
      }
      &[type="submit"] {
          padding: 0.4rem 1.5rem;
          display: block;
          margin: 0 auto;
        }
    }
    input {
      &.btn-datepicker {
        padding: 2rem 1rem;
        text-align: center;
      }
    }

  }

  form > div {
    border-right: 1px solid $my-gray;

    &:first-child,
    &:last-child {
      border: 0;
      // hack pre 100% autocopletu
      padding: 0;
    }
    &[class*="col-"] {
      padding: 0;
    }
  }

  .form-control {
    border: 0;
    width: 100%;
    background-color: #fff !important;
    box-shadow: none;
    border-radius: 0;
    &.tt-input {
      background-color: $light-blue!important;
      padding: 2rem 1rem;
    }
  }

}

.search-popup.show {
  display: block;
}

.daterangepicker {
  border-radius: 0!important;
  td.active , td.active:hover {
    background-color: $red!important;
  }
  .ranges {
    margin-top: 0!important;
    li {
      &.active {
        background-color: $red!important;
      }
    }
  }
}
@include media-breakpoint-down(lg) {
  .search-popup {
    top: 193px;
  }
  
}
@include media-breakpoint-down(md) {
  .daterangepicker::after {
    content: "";
    display: block;
    height: 100px;
    background-color:red;
    bottom: -101px!important;
    top: auto!important;
    border-color: $white!important;
  }
  .daterangepicker.ltr .ranges {
    border-bottom: 1px solid #ddd;
  }
  .daterangepicker .ranges ul {
    width: 100%!important;
  }
  .daterangepicker.show-calendar .ranges {
    float: none!important;
  }
  .daterangepicker .ranges li {
    width: 33.3333%;
    display: inline-block;
  }
  .search-popup form {
    border: 0;
  }
  .search-popup-close {
    display: block;
  }
  .search-popup .form-control.tt-input {
    text-align: center;
  }
  .search-popup {
    top: 74px;
    left: 0;
    position: fixed; 
    height: calc(100vh - 74px);
    max-height: 100vh!important;
    background-color: $white;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    .container {
      max-width: 100%;
      padding: 0; 
    }
  }
  .search-popup button i {
    float: right;
    margin: 4px 0 0 5px;
  }

  .search-popup form {
    &:before {
      display: none;
    }
  }
  .search-popup form {
     padding-right: 0;
  }
  .search-popup form > div {
    border-right: 0;
    border-bottom: 1px solid $my-gray;

    &:first-child {
      border-bottom: 1px solid $my-gray;
    }

    &:last-child {
      padding-top: ($grid-gutter-width / 2);
      padding-bottom: ($grid-gutter-width / 2);
    }
  }
  .daterangepicker.show-ranges .drp-calendar.left {
    border-left: 0!important;
  }

}
@include media-breakpoint-down(xs) {
  .daterangepicker .ranges li {
    width: 100%;
    display: block;
  } 
}