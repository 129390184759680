// Replace `$search` with `$replace` in `$string`
// Used on our SVG icon backgrounds for custom forms.
//
// @author Hugo Giraudel
// @param {String} $string - Initial string
// @param {String} $search - Substring to replace
// @param {String} $replace ('') - New value
// @return {String} - Updated string
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

$font-family-sans-serif: "Etelka", Roboto, "Helvetica Neue", Arial, sans-serif;
$headings-font-family:  "Etelka", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-weight-normal: 300;
$headings-font-weight: 700;

$light-blue: #fcfcfe;
$blue:    #0e0d36;
$red:    #e6163c;
$green:  #9fffcb;
$my-gray:   #dbdce9;
$my-blue:   #9fb3c3;
$black: #000000;

$body-bg: #f5f7fc;
$body-color: $blue;

$white: #ffffff;
$light: $white;

$border-color:  $my-gray;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px,
);

// Links
//
// Style anchor elements.

$link-color:                $blue;
$link-decoration:           none;
$link-hover-color:          $red;
$link-hover-decoration:     underline;


// navbar
$navbar-light-color:        $blue;
$navbar-light-hover-color:  $red;
$navbar-nav-link-padding-x: 1rem;

$breadcrumb-padding-y: 2rem;
$breadcrumb-bg: transparent;

$zindex-sticky:   1020;
$zindex-dropdown: $zindex-sticky + 1;

$input-placeholder-color: $my-blue;

$box-shadow-sm:  0 .125rem .05rem rgba($black, .075);
$box-shadow:     0 .5rem 1rem rgba($black, .15);
$box-shadow-lg:  0 1rem 3rem rgba($black, .175);
$box-shadow-btn: 0 0.5rem 2rem rgba($black, .075);

$font-size-base:    1rem; // Assumes the browser default, typically `16px`
$line-height-base:  1.5;

$h1-font-size:   $font-size-base * 2.5;
$h2-font-size:   $font-size-base * 2;
$h3-font-size:   $font-size-base * 1.75;
$h4-font-size:   $font-size-base * 1.375;
$h5-font-size:   $font-size-base * 1.25;
$h6-font-size:   $font-size-base;

$badge-border-radius: 0;

// Banner
$banner-bg-sales-points: url("../assets/kde_vyzdvihnut_listky.png");
$banner-bg-partners-info: url("../assets/pre_organizatorov.png");

$thanks-holder-img: url("../assets/podakovanie-bg.jpg");

$event-detail-min-height: 96px; //line-height * 4

$list-group-hover-bg: #fbfcfe;

$carousel-indicator-width:          12px;
$carousel-indicator-height:         12px;
$carousel-indicator-spacer:         3px;
$carousel-indicator-active-bg:      $my-gray;

$btn-padding-x: 2rem;

$table-hover-bg:      $list-group-hover-bg;
$table-border-color:  $my-gray;

$dropdown-item-padding-y:          1rem;
$dropdown-item-border-color:       #45446f;
$dropdown-padding-y:               1rem;
$dropdown-bg-dark:                 $blue;
$dropdown-border-color-dark:       rgba($black, .15);

$dropdown-link-bg-dark:            #25244b;
$dropdown-link-color-dark:         $my-blue;
$dropdown-link-hover-color-dark:   darken($my-blue, 5%);
$dropdown-link-hover-bg-dark:      lighten($dropdown-link-bg-dark, 5%);

$google-maps-width:  100%;
$google-maps-height:  509px;

$dropdown-header-color-dark:       $light;

$table-row-color-warning: #fcffec;

$nav-pills-border-radius:           15px;
$nav-pills-link-active-color:       $body-color;
$nav-pills-link-active-bg:          $light;

$masonry-columns-count:             4;
$masonry-columns-gap:               1.25rem;

$input-border-color:                $white;
$input-bg:                          $white;
$input-bg-dark:                     #f6f7fc;

$modal-header-padding: -1em -1em auto auto;
$modal-content-border-radius:       0;

$partners-jumbotron-img: url("/assets/spolupraca.png");

// svg imgs
$custom-select-indicator: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='1024' height='1024' viewBox='0 0 1024 1024'%3E%3Cpath fill='#{$red}' d='M504.832 796.672l-504.832-504.832 63.488-63.488 439.296 440.32 458.752-441.344 62.464 64.512-519.168 504.832z'/%3E%3C/svg%3E"), "#", "%23");
$perforation-left: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' id='Vrstva_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='9.2px' height='116px' viewBox='0 0 9.2 116.7' style='enable-background:new 0 0 9.2 116.7;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E.st0{fill:none;} .st1{fill:#FFFFFF;}%3C/style%3E%3Cg%3E%3Crect x='-1' y='-3' class='st0' width='12' height='103'/%3E%3C/g%3E%3Cg id='svg_1'%3E%3Cg%3E%3Cpath class='st1' d='M-2-2c0,38.9,0,81.7,0,120.6H9.2c0-3.9-2.5-9.4-6.1-10.7v-5.8c3.6-1.3,6.1-4.8,6.1-8.7c0-3.9-2.5-7.4-6.1-8.7v-6.1c3.6-1.3,6.1-4.9,6.1-8.7c0-3.8-2.5-7.4-6.1-8.7v-6.4c3.6-1.3,6.1-4.9,6.1-8.7c0-3.9-2.5-7.4-6.1-8.7v-5.3c3.5-1.3,5.8-4.4,6.1-8.1v-1c0-3.9-2.5-7.4-6.1-8.7V8.5C8,5.4,9.1,2.3,9.2-2H-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"), "#", "%23");
$perforation-right: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' id='Vrstva_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='9.2px' height='116px' viewBox='0 0 9.2 116.7' style='enable-background:new 0 0 9.2 116.7;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E.st0{fill:none;} .st1{fill:#FFFFFF;}%3C/style%3E%3Cg%3E%3Crect x='-1' y='-2' class='st0' width='12' height='102'/%3E%3C/g%3E%3Cg id='svg_1'%3E%3Cg%3E%3Cpath class='st1' d='M10.2,118.6c0-38.9,0-81.7,0-120.6L0-2C0,1.9,2.5,7.4,6.1,8.7l0,5.8C2.5,15.8,0,19.3,0,23.2c0,3.9,2.5,7.4,6.1,8.7l0,6.1C2.5,39.3,0,42.9,0,46.7c0,3.8,2.5,7.4,6.1,8.7l0,6.4C2.5,63.1,0,66.7,0,70.5c0,3.9,2.5,7.4,6.1,8.7l0,5.3c-3.5,1.3-5.8,4.4-6.1,8.1l0,1c0,3.9,2.5,7.4,6.1,8.7l0,5.8c-4.9,3.1-6,6.2-6.1,10.5L10.2,118.6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"), "#", "%23");
$bottom-perforation: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='100px' height='100px' viewBox='0 0 100 10' style='enable-background:new 0 0 100 100;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0{fill:#1E1E1E;} .st1{fill:#202020;} .st2{fill:#1A1A18;} .st3{opacity:0;clip-path:url(#SVGID_2_);} .st4{fill:#FFFFFF;} .st5{fill:#0E0D36;}%3C/style%3E%3Cpath class='st5' d='M95.7,48l-0.1-0.4h-5.6L89.8,48c-0.6,1.9-2.4,3.3-4.4,3.3c-2,0-3.8-1.3-4.4-3.3L81,47.6h-5.4L75.5,48c-0.6,1.9-2.3,3.2-4.3,3.2c-2,0-3.8-1.3-4.3-3.2l-0.1-0.4h-5.2L61.3,48c-0.5,1.7-2,3-3.7,3.2v0l-0.7,0c0,0-0.1,0-0.1,0c-2,0-3.8-1.3-4.3-3.2l-0.1-0.4h-4.9L47.3,48c-0.6,1.9-2.3,3.2-4.3,3.2c-2,0-3.8-1.3-4.3-3.2l-0.1-0.4h-5.6L32.8,48c-0.6,1.9-2.4,3.3-4.4,3.3s-3.8-1.3-4.4-3.3l-0.1-0.4h-5.4L18.4,48c-0.6,1.9-2.3,3.2-4.3,3.2c-2,0-3.8-1.3-4.3-3.2l-0.1-0.4H4.4L4.3,48C3.8,49.9,2,51.2,0,51.2v1.2c2.4,0,4.5-1.5,5.3-3.7h3.5c0.8,2.2,2.9,3.7,5.3,3.7c2.4,0,4.5-1.5,5.3-3.7h3.7c0.8,2.2,3,3.7,5.3,3.7s4.5-1.5,5.3-3.7h3.9c0.8,2.2,3,3.7,5.3,3.7c2.4,0,4.5-1.5,5.3-3.7h3.2c0.8,2.1,2.7,3.5,4.9,3.7l0.6,0c2.4,0,4.5-1.5,5.3-3.7h3.5c0.8,2.2,2.9,3.7,5.3,3.7c2.4,0,4.5-1.5,5.3-3.7h3.7c0.8,2.2,3,3.7,5.3,3.7c2.4,0,4.5-1.5,5.3-3.7h3.9c0.8,2.2,2.9,3.7,5.3,3.7v-1.2C98,51.2,96.2,49.9,95.7,48z'/%3E%3C/svg%3E"), "#", "%23");
$right-perforation: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='100px' height='100px' viewBox='0 0 10 100' style='enable-background:new 0 0 100 100;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0{fill:#1E1E1E;} .st1{fill:#202020;} .st2{fill:#1A1A18;} .st3{opacity:0;clip-path:url(#SVGID_2_);} .st4{fill:#FFFFFF;} .st5{fill:#0E0D36;}%3C/style%3E%3Cpath class='st5' d='M48,4.3l-0.4,0.1v5.6l0.4,0.1c1.9,0.6,3.3,2.4,3.3,4.4c0,2-1.3,3.8-3.3,4.4L47.6,19v5.4l0.4,0.1c1.9,0.6,3.2,2.3,3.2,4.3c0,2-1.3,3.8-3.2,4.3l-0.4,0.1v5.2l0.4,0.1c1.7,0.5,3,2,3.2,3.7h0l0,0.7c0,0,0,0.1,0,0.1c0,2-1.3,3.8-3.2,4.3l-0.4,0.1v4.9l0.4,0.1c1.9,0.6,3.2,2.3,3.2,4.3c0,2-1.3,3.8-3.2,4.3l-0.4,0.1v5.6l0.4,0.1c1.9,0.6,3.3,2.4,3.3,4.4s-1.3,3.8-3.3,4.4l-0.4,0.1v5.4l0.4,0.1c1.9,0.6,3.2,2.3,3.2,4.3c0,2-1.3,3.8-3.2,4.3l-0.4,0.1v5.2l0.4,0.1c1.9,0.6,3.2,2.3,3.2,4.3h1.2c0-2.4-1.5-4.5-3.7-5.3v-3.5c2.2-0.8,3.7-2.9,3.7-5.3c0-2.4-1.5-4.5-3.7-5.3v-3.7c2.2-0.8,3.7-3,3.7-5.3c0-2.4-1.5-4.5-3.7-5.3v-3.9c2.2-0.8,3.7-3,3.7-5.3c0-2.4-1.5-4.5-3.7-5.3v-3.2c2.1-0.8,3.5-2.7,3.7-4.9l0-0.6c0-2.4-1.5-4.5-3.7-5.3v-3.5c2.2-0.8,3.7-2.9,3.7-5.3c0-2.4-1.5-4.5-3.7-5.3v-3.7c2.2-0.8,3.7-3,3.7-5.3c0-2.4-1.5-4.5-3.7-5.3V5.3c2.2-0.8,3.7-2.9,3.7-5.3h-1.2C51.2,2,49.9,3.8,48,4.3z'/%3E%3C/svg%3E"), "#", "%23");


$icomoon-font-path: "../fonts";

$icon-37: "\e906";
$icon-36: "\e907";
$icon-35: "\e908";
$icon-34: "\e909";
$icon-33: "\e90a";
$icon-32: "\e90b";
$icon-31: "\e90c";
$icon-30: "\e90d";
$icon-29: "\e90e";
$icon-28: "\e90f";
$icon-27: "\e910";
$icon-26: "\e911";
$icon-25: "\e912";
$icon-24: "\e913";
$icon-23: "\e914";
$icon-22: "\e915";
$icon-21: "\e916";
$icon-20: "\e917";
$icon-19: "\e918";
$icon-18: "\e919";
$icon-17: "\e91a";
$icon-16: "\e91b";
$icon-15: "\e91c";
$icon-14: "\e91d";
$icon-13: "\e91e";
$icon-11: "\e920";
$icon-10: "\e921";
$icon-09: "\e922";
$icon-08: "\e923";
$icon-07: "\e924";
$icon-06: "\e925";
$icon-05: "\e926";
$icon-04: "\e927";
$icon-03: "\e928";
$icon-02: "\e929";
$icon-01: "\e92a";

