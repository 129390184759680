.event__detail {

  &.collapse:not(.show) {
    height: $event-detail-min-height;
    overflow: hidden;

    display: -webkit-box;
    -webkit-line-clamp: 4; //
    -webkit-box-orient: vertical;
  }

  &.show {
    height: auto;
  }

  &.collapsing {
    min-height: $event-detail-min-height;
  }

}

.event__dates {

  .list-group-item a {
    text-decoration: none;
  }

  .list-group-item span {
    font-size: 0.88rem;
  }

  .list-group-item i {
    font-size: 2rem;
  }

  .list-group-item:hover {
    background-color: $list-group-hover-bg;
  }
}

ul.list-group-striped {
  .list-group-item:nth-child(even) { background-color: $list-group-hover-bg; }
}

.event__figure {
  position: relative;
  margin-bottom: ( $grid-gutter-width /2 );

  .perforation-bottom {
    width: 100%;
    overflow: hidden;
    position: absolute;
    height: 10px;
    bottom: -2px;
    z-index: 1;

    img {
      display: block;
      height: 8px;
      transform: rotate(180deg)
    }
  }
}