.btn-collapse {
  &:before {
    content: attr(data-textcollapse)
  }

  & > i {
    transform: rotate(180deg);
    display: inline-block;
  }

  &.collapsed:before {
    content: attr(data-textcollapsed);
  }

  &.collapsed > i {
    transform: rotate(0);
  }
}

.btn-wide {
  padding-left: $btn-padding-x;
  padding-right: $btn-padding-x;
}

.btn-light {
  box-shadow: $box-shadow-btn;
}