.table-custom {

  border-right: 1px solid $table-border-color;
  border-bottom: 1px solid $table-border-color;
  border-left: 1px solid $table-border-color;

  thead th {
    vertical-align: bottom;
    border-bottom: 1px solid $table-border-color;
    font-size: .95rem;
  }
}

.table-warning, .table-warning>th, .table-warning>td {
  background-color: $table-row-color-warning ;
}

.disabled {
  td, td a {
    color: $my-gray !important;
  }
  .icon-36, .dropdown-menu a {
    color: $body-color !important;
  }
}