
// overide default bootstrap variables from _variables.scss
@import "_custom";

// impor Bootstrap
/*!
 * Bootstrap v4.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
//@import "node_modules/bootstrap/scss/code";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/dropdown";
//@import "node_modules/bootstrap/scss/button-group";
//@import "node_modules/bootstrap/scss/input-group";
@import "node_modules/bootstrap/scss/custom-forms";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/card";
@import "node_modules/bootstrap/scss/breadcrumb";
@import "node_modules/bootstrap/scss/pagination";
@import "node_modules/bootstrap/scss/badge";
//@import "node_modules/bootstrap/scss/jumbotron";
@import "node_modules/bootstrap/scss/alert";
//@import "node_modules/bootstrap/scss/progress";
//@import "node_modules/bootstrap/scss/media";
@import "node_modules/bootstrap/scss/list-group";
@import "node_modules/bootstrap/scss/close";
@import "node_modules/bootstrap/scss/modal";
//@import "node_modules/bootstrap/scss/tooltip";
//@import "node_modules/bootstrap/scss/popover";
//@import "node_modules/bootstrap/scss/carousel";
@import "node_modules/bootstrap/scss/utilities";
//@import "node_modules/bootstrap/scss/print";

// hover efects
@import "hover";

// fonts (Etelka Light, Etelka Bold)
@import "fonts";
@import "typo";
@import "buttons";
@import "dropdown";
@import "table";
@import "alert";
@import "steps";
@import "badges";

// custom icons font
@import "icons";

// components
@import "search-popup";
@import "forms";
@import "navbar";
@import "carousels";
@import "slider";
@import "box";
@import "banners";
@import "google-infobox";
@import "modals";
@import "footer";

// vendors
//@import "node_modules/choices.js/src/styles/choices.scss";
@import "node_modules/awesome-bootstrap-checkbox/awesome-bootstrap-checkbox.scss";
@import "node_modules/bootstrap-multiselect/dist/css/bootstrap-multiselect";
@import "node_modules/daterangepicker/daterangepicker";
@import "node_modules/slick-carousel/slick/slick.scss";
@import "vendor/typeaheadjs.scss";

//pages
@import "event-detail";
@import "spolupraca";

figure {
  // Apply a consistent margin strategy (matches our type styles).
  margin: 0;
}

.thanks-holder {

  background-image: $thanks-holder-img;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;

  @include media-breakpoint-up(md) {
  background-position: 50% 100%;
  background-size: auto;

  }

  .bg-light {
    background-color: $body-bg !important;
  }

}

.box-shadow {
  box-shadow: $box-shadow;
}

.overflow-x-hidden {
  //@include media-breakpoint-down(md) {
    overflow-x: hidden;
    overflow-y: scroll !important;
  //}
}

.clickable {
  cursor: pointer;
}

.modal-full {
  .modal-dialog {
    min-width: 100%;
  }
}

// using custom properties for image wrapper for generating height based on aspect ratio 
// source https://css-tricks.com/aspect-ratio-boxes/#article-header-id-7

[style*="--aspect-ratio"] > :first-child {
  width: 100%;
}
[style*="--aspect-ratio"] > img {  
  height: auto;
} 
@supports (--custom:property) {
  [style*="--aspect-ratio"] {
    position: relative;
  }
  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }  
  [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }  
}


// custom checkboxes and radios

$font-family-icon: 'predpredaj-icons' !default;
$fa-var-check: "\e91b" !default;

.abc-checkbox,
.abc-radio {
  
  label {
    &::before {
      top: -2px;
      width: 1.2em;
      height: 1.2em;
      border: 1px solid #000;
      background-color: #fff;
    }
    &::after {
      top: -2px;
      width: 1.1em;
      height: 1.1em;
      padding-top: 0;
      font-size: 13px;
    }
  }

  input[type="checkbox"] {
    &:checked + label::after{
      font-family: $font-family-icon;
      content: $check-icon;
    }
  }
  
  &-danger {
    label {
      color: $red;
      &::before {
        border: 1px solid $red;
      }
    }
  }
}

.abc-radio {
  margin-left: 16px;
  
  label {
    &::after {
      top: 1px;
      width: 1.1em;
      height: 1.1em;
      background-color: #e6163c;
      font-size: 12px;
    }
  }
}